import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/Layout"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"

import oko from "../assets/img/new/eye.svg"
import oko_bg from "../assets/img/new/oko_bg.svg"
import target from "../assets/img/new/target.svg"
import target_bg from "../assets/img/new/target_bg.svg"
import money from "../assets/img/new/money.svg"
import money_bg from "../assets/img/new/money_bg.svg"

import trafic from "../assets/img/new/dedykowany-opiekun.svg"
import trafic_bg from "../assets/img/ikonki/strony/dedykowany-opiekun-znak.webp"
import chart from "../assets/img/new/monitoring-fraz.svg"
import chart_bg from "../assets/img/ikonki/strony/monitoring-fraz-znak.webp"
import report from "../assets/img/new/raport.svg"
import report_bg from "../assets/img/ikonki/strony/raport-znak.webp"

import content from "../assets/img/old/grafika-8.webp"
import optimize from "../assets/img/old/grafika-10.webp"
import optimize2 from "../assets/img/old/grafika-9.webp"
import link_building from "../assets/img/old/grafika-14.webp"
import stats from "../assets/img/old/grafika-12.webp"

import footer_bg from "../assets/img/new/tlo-konsultacje-03-4.webp"
import HeaderImage from "../assets/img/new/tlo-serch-01.webp"



import "../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

const WebsitePositioning = () => {
  
  const { t } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage("de")
  }, [])
  return (
    <Layout
      seo={{
        title:
          "Positionierung von Webseiten • Lassen Sie Ihre Webseite von der WeNet optimieren",
        description:
          "Nutzen Sie die Dienstleistung der Webseite-Positionierung • Wir sind Spezialisten für die Positionierung von Webseiten - wir übernehmen das für Sie!",
        hreflangs: {
          pl: "pozycjonowanie-stron/",
          en: "en/website-positioning/",
          de: "de/positionierung-von-webseiten/",
        },
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Heim",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/de/positionierung-von-webseiten/",
                name: t("seo-title-website-positioning"),
              },
            },
          ],
        },
      }}
    >
      <div
        className="hero-header-page"
        style={{ backgroundImage: `url("${HeaderImage}")` }}
      >
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              {
                url: t("link-url-positioning"),
                text: t("link-text-positioning"),
              },
            ]}
          />
        </div>
        <h1 style={{ marginTop: "40px" }}>Positionierung von Webseiten</h1>
        <p style={{ marginBottom: "40px" }}>
          Sie wissen nicht, wo Sie mit dem komplizierten Optimierungsprozess
          anfangen sollen? <br /> Wir können Ihnen helfen! Überlassen Sie uns
          die Angelegenheiten Ihrer Webseite. <br /> Wir sind Spezialisten für
          die Positionierung von Webseiten - wir übernehmen das für Sie.
        </p>
      </div>

      <section className="standard-section">
        <div className="container text-center">
          <h2 style={{ fontSize: "36px" }}>
            Was sind die <strong>Vorteile von SEO</strong>?
          </h2>
        </div>
      </section>

      <section className="section-intro no-padding-top no-padding-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img
                    src={oko}
                    alt="Bessere Sichtbarkeit der Webseite"
                    style={{ marginBottom: "15px", marginTop: "10px" }}
                  />
                </div>
                <h3>Bessere Sichtbarkeit der Webseite</h3>
                <p>
                  Sie erhalten eine höhere Position in der Suchmaschine und
                  einen Vorteil gegenüber Ihren Mitbewerbern.
                </p>
                <img src={oko_bg} className="bg-icon" alt="Tło karty" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img src={target} alt="Zugang zu mehr Benutzern" />
                </div>
                <h3>Zugang zu mehr Benutzern</h3>
                <p>
                  mehr Kunden besuchen Ihre Webseite und Sie bauen Vertrauen
                  auf.
                </p>
                <img src={target_bg} className="bg-icon" alt="Tło karty" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img
                    src={money}
                    alt="Gewinne und Einsparungen für die Firma"
                  />
                </div>
                <h3>Gewinne und Einsparungen für die Firma</h3>
                <p>
                  Investitionen in Positionierung zahlen sich aus und Sie können
                  mit langfristigen Ergebnissen rechnen.
                </p>
                <img src={money_bg} className="bg-icon" alt="Tło karty" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="standard-section">
        <div className="container">
          <h2 className="text-center" style={{ fontSize: "36px" }}>
            Worin bestehen unsere <strong>Positionierungsleistungen</strong>?
          </h2>
        </div>
      </section>

      <section className="text-image image-right">
        <div className="half">
          <div className="content">
            <h2 style={{ fontSize: "30px" }}>
              <strong>Optimierung der Inhalte</strong>
            </h2>
            <ul>
              <li>
                Wir führen eine detaillierte Analyse der Texte unter
                Berücksichtigung von Schlüsselwörtern durch,
              </li>
              <li>
                Wir optimieren Inhalte, Meta-Daten, H1-, H2-, H3-Überschriften
                und interne Verlinkung,
              </li>
              <li>
                Wir überprüfen die Webseite auf Duplizierung der Inhalte,{" "}
              </li>
              <li>
                Wir beseitigen die Keyword-Kannibalisierung der Unterseiten,{" "}
              </li>
              <li>Wir analysieren die interne Verlinkung der Webseite.</li>
            </ul>
          </div>
        </div>
        <div
          className="half d-none d-lg-block"
          style={{
            background: `url(${content}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
      </section>

      <section className="text-image image-left">
        <div
          className="half"
          style={{
            background: `url(${optimize}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
        <div className="half">
          <div className="content">
            <h2 style={{ fontSize: "30px" }}>
              <strong>Technische Optimierung</strong>
            </h2>
            <ul>
              <li>Wir kümmern uns um die richtige Struktur der Webseite,</li>
              <li>Wir beseitigen SEO-unfreundliche Techniken, </li>
              <li>
                Wir überprüfen die URL-Adressen, um sie freundlich für
                Suchmaschinen zu gestalten,
              </li>
              <li>
                Wir führen einen Geschwindigkeitstest durch und prüfen die
                Reaktionsfähigkeit der Webseite,
              </li>
              <li>
                Wir verbessern die bestehenden Weiterleitungen oder fügen neue
                hinzu, die freundlich für Suchmaschinen sind.
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="text-image image-right reverse-flex">
        <div className="half">
          <div className="content">
            <h2 style={{ fontSize: "30px" }}>
              <strong>Technische Optimierung - Fortsetzung</strong>
            </h2>
            <ul>
              <li>
                Wir kümmern uns um Grafiken, damit sie die richtigen
                Eigenschaften haben und schneller laden,
              </li>
              <li>
                Wir implementieren ein iframe mit dem Google Maps-Standort des
                Unternehmens auf der Webseite,
              </li>
              <li>
                Wir korrigieren andere technische Fehler, die die Indexierung
                der Webseite oder ihres Teils behindern oder blockieren.
              </li>
            </ul>
          </div>
        </div>
        <div
          className="half"
          style={{
            background: `url(${optimize2}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
      </section>

      <section className="text-image image-left">
        <div
          className="half"
          style={{
            background: `url(${link_building}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
        <div className="half">
          <div className="content">
            <h2 style={{ fontSize: "30px" }}>
              <strong>Link Building</strong>
            </h2>
            <ul>
              <li>
                Wir schreiben und veröffentlichen Content-Marketing-Artikel auf
                wertvollen Webseiten,
              </li>
              <li>
                Wir erstellen Visitenkarten in wertvollen Firmenkatalogen und
                Branchenwebseiten, die auf die Webseite des Kunden verweisen,
              </li>
              <li>
                Wir erstellen oder aktualisieren Google My Business und fügen
                Einträge hinzu,
              </li>
              <li>
                Wir bauen das Image der Firma im Internet durch Einträge auf,
              </li>
              <li>Wir tragen die Firma in SEO-Kataloge ein,</li>
              <li>
                Wir richten einen Blog ein, konfigurieren und pflegen ihn,
              </li>
              <li>
                Wir richten eine Fanpage ein und betreiben Kommunikation in
                Sozialen Medien,
              </li>
              <li>
                Wir installieren Plugins für Ihre Profile in Sozialen Medien,
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="text-image image-right reverse-flex">
        <div className="half">
          <div className="content">
            <h2 style={{ fontSize: "30px" }}>
              <strong>Statistik</strong>
            </h2>
            <ul>
              <li>Wir fügen die Webseite zur Google Search Console hinzu,</li>
              <li>Wir fügen die Webseite zu Google Analytics hinzu,</li>
              <li>Wir analysieren die Statistiken der Webseite,</li>
              <li>Wir prüfen die Position der Schlüsselbegriffe,</li>
              <li>Wir prüfen die Sichtbarkeit der Webseite in Google.</li>
            </ul>
          </div>
        </div>
        <div
          className="half"
          style={{
            background: `url(${stats}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
      </section>

      <section className="section-intro">
        <div className="container">
          <div className="row">
            <div className="row-md-12">
              <h2 style={{ fontSize: "36px" }}>
                <strong>Wir bieten:</strong>
              </h2>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img src={trafic} alt="Einen dedizierten Betreuer" />
                </div>
                <h3>Einen dedizierten Betreuer</h3>
                <ul>
                  <li>der die Ergebnisse Ihrer Webseite überwachen wird,</li>
                  <li>
                    der Ihnen jeden Tag zur Verfügung stehen und Erklärungen
                    geben wird.
                  </li>
                </ul>
                <img
                  src={trafic_bg}
                  className="bg-icon"
                  alt="Einen dedizierten Betreuer"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img
                    src={chart}
                    alt="Phrasen- und Sichtbarkeit-Überwachung"
                  />
                </div>
                <h3>Phrasen- und Sichtbarkeit-Überwachung</h3>
                <ul>
                  <li>Sie haben Zugang zu detaillierten Daten,</li>
                  <li>Sie können die Ergebnisse unserer Arbeit verfolgen.</li>
                </ul>
                <img
                  src={chart_bg}
                  className="bg-icon"
                  alt="Phrasen- und Sichtbarkeit-Überwachung"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img src={report} alt="Monatlicher Bericht" />
                </div>
                <h3>Monatlicher Bericht</h3>
                <ul>
                  <li>
                    wo Sie die wichtigsten Daten über den Fortschritt unserer
                    Aktivitäten finden,
                  </li>
                  <li>Sie haben volle Kontrolle über den gesamten Prozess.</li>
                </ul>
                <img
                  src={report_bg}
                  className="bg-icon"
                  alt="Monatlicher Bericht"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="outro"
        style={{
          backgroundImage: `url(${footer_bg})`,
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <h3>
                Nutzen Sie unser SEO-Angebot und lassen Sie Ihre Webseite von
                den Spezialisten der
                <span style={{ display: "inline-block" }}>WeNet</span>{" "}
                optimieren – stärken Sie die Position Ihrer Internetseite!
              </h3>

              <Link to={t("link-url-contact")} className="btn btn-primary">
                Ich möchte einen kostenlosen Kostenvoranschlag für die
                Optimierung erhalten
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default WebsitePositioning
